body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
}
body h1,
body h2,
body h3,
body h4,
body a,
body input {
  color: #333333;
  margin: 0;
}
body h1 {
  font-size: 21px;
}
body h2 {
  font-size: 17px;
}
body h3 {
  font-size: 15px;
}
body a {
  text-decoration: none;
}
body ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
body ul li {
  line-height: 18px;
  font-size: 16px;
}
body form {
  margin: 0;
}
body form input,
body form textarea {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
body form input:focus,
body form textarea:focus {
  border: 1px solid #ff7675;
}
body button {
  border-radius: 2px;
  border: 1px solid #dfe6e9;
  font-size: 14px;
  margin: 0;
  padding: 0;
}
body button:hover {
  cursor: pointer;
}
body button:focus {
  border: 1px solid #ff7675;
  outline: none;
}
.search,
.sign-up,
.contact-input {
  width: 250px;
  height: 40px;
  font-size: 20px;
  padding: 10px;
  outline: none;
}
.contact-textarea {
  min-height: 120px;
}
.navigation {
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 0;
}
.navigation .logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  letter-spacing: -6px;
  font-size: 40px;
  height: 50px;
}
.navigation .social-icons {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 100px;
  margin-top: 20px;
}
.navigation .social-icons .icon {
  width: 25px;
}
.navigation .items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.navigation .items a,
.navigation .items li {
  width: 100px;
  display: block;
  text-align: center;
  font-size: 18px;
}
.search-icon:hover {
  cursor: pointer;
}
.tile-search {
  height: 100px;
  line-height: 100px;
  text-align: center;
  vertical-align: middle;
}
.tile-container .tile-nav {
  padding-top: 10px;
  text-align: center;
}
.tile-container .tile-nav ul {
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
}
.tile-container .tile-nav ul::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.tile-container .tile-nav ul .category {
  text-transform: capitalize;
  display: inline-block;
  color: #707175;
  margin-top: 10px;
}
.tile-container .tile-nav ul .category a {
  display: block;
  border-right: 1px solid #707175;
  color: #707175;
  padding: 0 15px;
  font-size: 14px;
}
.tile-container .tile-nav ul .category:last-child a {
  border-right: none;
}
.tile-container .tile-nav ul .category.active a {
  font-weight: bold;
}
.tile-container .tiles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 20px 0 0 0;
}
.tile-container .tiles .carousel {
  margin: 10px auto;
  width: 350px;
  height: 300px;
  position: relative;
}
.tile-container .tiles .carousel:hover .next,
.tile-container .tiles .carousel:hover .prev {
  display: block;
}
.tile-container .tiles .carousel .product-link {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
}
.tile-container .tiles .carousel .product-link .tile {
  width: 100%;
}
.tile-container .tiles .carousel .product-link .tile .thumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 225px;
}
.tile-container .tiles .carousel .product-link .tile .thumbs .thumb {
  margin: 0 auto;
}
.tile-container .tiles .carousel .product-link .tile .thumbs .thumb.hide {
  opacity: 0;
}
.tile-container .tiles .carousel .product-link .tile .thumbs .thumb.show {
  opacity: 1;
}
.tile-container .tiles .carousel .product-link .tile .price {
  position: absolute;
}
.tile-container .tiles .carousel .product-link .tile.show {
  display: inline-block;
}
.tile-container .tiles .carousel .product-link .tile.hide {
  display: none;
}
.tile-container .tiles .carousel .next,
.tile-container .tiles .carousel .prev {
  width: 25px;
  border: 0;
  height: 25px;
  opacity: 0.5;
  position: absolute;
  margin-top: 30%;
  top: 0;
  display: none;
}
.tile-container .tiles .carousel .next:hover,
.tile-container .tiles .carousel .prev:hover {
  opacity: 0.8;
}
.tile-container .tiles .carousel .next {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iZ3JheSIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNOC4xMjIgMjRsLTQuMTIyLTQgOC04LTgtOCA0LjEyMi00IDExLjg3OCAxMnoiLz48L3N2Zz4=) no-repeat;
  right: 0;
}
.tile-container .tiles .carousel .prev {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iZ3JheSIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNOC4xMjIgMjRsLTQuMTIyLTQgOC04LTgtOCA0LjEyMi00IDExLjg3OCAxMnoiLz48L3N2Zz4=) no-repeat;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.tile-container .hide {
  display: none;
}
.contact-us-popup {
  display: none;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 500px;
  max-height: 500px;
  margin: 0 auto;
  padding: 15px;
  background: #fab1a0;
}
.contact-us-popup.show,
.contact-us-popup .form {
  display: -ms-grid;
  display: grid;
}
.contact-us-popup div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.contact-us-popup div label {
  -webkit-box-flex: 0.3;
      -ms-flex: 0.3;
          flex: 0.3;
}
.contact-us-popup div textarea,
.contact-us-popup div input {
  -webkit-box-flex: 0.7;
      -ms-flex: 0.7;
          flex: 0.7;
  border: 1px solid #dfe6e9;
  margin: 2px 0;
}
.contact-us-popup div textarea:focus,
.contact-us-popup div input:focus {
  border: 1px solid #ff7675;
}
.contact-us-popup .close {
  width: 25px;
  height: 25px;
}
.contact-us-popup .send {
  width: 100px;
  height: 40px;
  position: relative;
  left: calc(100% - 100px);
}
.about-us,
.tos,
.privacy {
  width: 75%;
  margin: 0 auto;
  padding: 20px;
}
.footer {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  height: 250px;
  border-top: 1px solid gray;
}
.footer div {
  text-align: center;
  padding: 20px;
}
.footer div.copyright {
  -ms-grid-column: 1;
      grid-column-start: 1;
  -ms-grid-column-span: 3;
  grid-column-end: 4;
  -ms-grid-row: 2;
      grid-row-start: 2;
  -ms-grid-row-span: 0;
  grid-row-end: 2;
  text-align: center;
}
.footer div .social-icon {
  display: inline-block;
}
.footer .submit-email {
  height: 40px;
  width: 60px;
  vertical-align: top;
}
